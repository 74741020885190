@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Lobster&family=Roboto:ital,wght@0,400;0,500;0,900;1,500;1,900&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

section {
  height: 100vh;
}

.roboto {
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
}

.lobster {
  font-family: 'Lobster', cursive;
}

.vh-half {
  height: 30vh;
}

.profile-pic {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: url(./img/golden_gate_pic.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@keyframes typing {
  from { width: 0; }
  to { width: 100%; }
}

@keyframes deleting {
  from { width: 100%; }
  to { width: 0; }
}

@keyframes blink-caret {
  from, to { border-color: transparent; }
  50% { border-color: black; }
}

.typing {
  font-family: monospace;
  overflow: hidden;
  white-space: nowrap;
  margin: 0 auto;
  letter-spacing: .10em;
}